define('@fortawesome/free-regular-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faClipboard = {
    prefix: 'far',
    iconName: 'clipboard',
    icon: [384, 512, [128203], "f328", "M280 64h40c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128C0 92.7 28.7 64 64 64h40 9.6C121 27.5 153.3 0 192 0s71 27.5 78.4 64H280zM64 112c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H320c8.8 0 16-7.2 16-16V128c0-8.8-7.2-16-16-16H304v24c0 13.3-10.7 24-24 24H192 104c-13.3 0-24-10.7-24-24V112H64zm128-8a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"]
  };
  var faWindowMaximize = {
    prefix: 'far',
    iconName: 'window-maximize',
    icon: [512, 512, [128470], "f2d0", "M.3 89.5C.1 91.6 0 93.8 0 96V224 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64V224 96c0-35.3-28.7-64-64-64H64c-2.2 0-4.4 .1-6.5 .3c-9.2 .9-17.8 3.8-25.5 8.2C21.8 46.5 13.4 55.1 7.7 65.5c-3.9 7.3-6.5 15.4-7.4 24zM48 224H464l0 192c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16l0-192z"]
  };
  var faWindowMinimize = {
    prefix: 'far',
    iconName: 'window-minimize',
    icon: [512, 512, [128469], "f2d1", "M24 432c-13.3 0-24 10.7-24 24s10.7 24 24 24H488c13.3 0 24-10.7 24-24s-10.7-24-24-24H24z"]
  };

  exports.faClipboard = faClipboard;
  exports.faWindowMaximize = faWindowMaximize;
  exports.faWindowMinimize = faWindowMinimize;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
